import React, { useState } from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Obfuscate from "react-obfuscate"
import { Link } from "gatsby-plugin-spinner"
import { showLoading, clearLoading } from "../actions"
import { useDispatch } from "react-redux"

const NousContacter = (props) => {
  const [state, setState] = useState({
    msg: "",
    email: "",
    number: 0,
    isSent: false,
    captcha: false,
  })
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const handleUpdate = (event) => {
    setState({ ...state, [event.target.name]: event.target.value })
  }

  const handleSubmit = (evt) => {
    evt.preventDefault()

    const data = new FormData()
    data.append("msg", state.msg)
    data.append("email", state.email)
    data.append("site", process.env.SITE)

    var url = process.env.GATSBY_API_URL + "/index.php?controller=inscription"
    if (parseInt(state.number) === 5) {
      dispatch(showLoading("Envoi du message en cours"))
      setLoading(true)
      fetch(url, {
        method: "POST",
        body: data,
      }).then((response) => {
        dispatch(clearLoading(true, ""))
        setLoading(false)
        setState({ ...state, isSent: true, captcha: false })

        return response.text()
      })
    } else {
      setState({ ...state, captcha: true })
    }
  }

  return (
    <>
      <Layout banner={false} location={props.location}>
        <SEO
          title="Nous contacter"
          description="Demande d'informations"
          canonical={process.env.GATSBY_URL_ACTUEL + "/nous-contacter/"}
          jsonLd={[
            {
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              itemListElement: [
                {
                  "@type": "ListItem",
                  position: 1,
                  name: "Accueil",
                  item: process.env.GATSBY_URL_ACTUEL,
                },
                {
                  "@type": "ListItem",
                  position: 2,
                  name: "Nous contacter",
                  item: `${process.env.GATSBY_URL_ACTUEL}/nous-contacter/`,
                },
              ],
            },
          ]}
          robots="noindex"
        />
        <nav className="breadcrumb mb-0 mt-4">
          <ul>
            <li>
              <Link to="/">Accueil</Link>
            </li>
            <li>
              <span className="pl-3">Nous contacter</span>
            </li>
          </ul>
        </nav>
        <h1>Service client - Contactez-nous</h1>
        <div className="container">
          <p>
            Nous sommes à votre écoute au{" "}
            <strong>
              <Obfuscate tel="03 89 06 00 93" />
            </strong>{" "}
            du lundi au vendredi 9h-18h et samedi 9h-12h.
          </p>
          <div className="h2">Envoyer un message</div>
          {!state.isSent ? (
            <div className="field" style={{ paddingBottom: "6rem" }}>
              <form onSubmit={handleSubmit}>
                <div className="columns">
                  <div className="column is-4">
                    <div className="field">
                      <label className="label" htmlFor="email">
                        Email
                      </label>
                      <div className="control">
                        <input className="input" name="email" id="email" type="email" required placeholder="Votre adresse email" onChange={handleUpdate} />
                      </div>
                    </div>

                    <div className="field">
                      <label className="label" htmlFor="number">
                        Donner le nombre de lettres du mots &quot;CADRE&quot;
                      </label>
                      <i>exemple, pour &quot;FRANCE&quot; c&apos;est : 6</i>
                      <div className="control">
                        <input className="input" name="number" id="number" type="number" required onChange={handleUpdate} style={{ width: "20%" }} />
                        {state.captcha ? <i style={{ color: "red", marginLeft: "1rem" }}>le nombre est incorrect !</i> : ""}
                      </div>
                    </div>
                  </div>

                  <div className="column is-8">
                    <div className="field">
                      <label className="label" htmlFor="msg">
                        Message
                      </label>
                      <div className="control">
                        <textarea name="msg" id="msg" className="textarea" rows="10" required onChange={handleUpdate}></textarea>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field">
                  <div className="control">
                    <button className={`button is-primary is-large ${loading ? "is-loading" : ""}`} data-cy="contact-send">
                      Envoyer
                    </button>
                  </div>
                </div>
              </form>
            </div>
          ) : (
            <div className="notification is-success" style={{ marginBottom: "3.6rem", textAlign: "center", fontSize: "1.4rem" }}>
              Merci pour votre message. Nous vous répondrons dans les plus brefs délais.
            </div>
          )}
        </div>
      </Layout>
    </>
  )
}

NousContacter.propTypes = {
  identifiants: PropTypes.object,
  location: PropTypes.object,
}

export default NousContacter
